const eventItems = document.querySelectorAll('.event-item');
const bkgContainer = document.querySelector('.bkg-container');

/*
eventItems.forEach((event) =>{
    event.addEventListener('mouseenter', () =>{
        const bkg = event.dataset.background;
        bkgContainer.innerHTML = bkg;
    });
});*/



eventItems.forEach((item) =>{
   item.addEventListener('mouseenter', () =>{

       setCoverPosition(item);

    });
});



function setCoverPosition(item){

    const eventImages = item.querySelector('.event-image-container');
    const container = document.querySelector('.container');
    const containerHeight = container.offsetHeight;

    var parentEloffset = eventImages.parentElement.offsetTop;
    var parentElHeight = eventImages.parentElement.offsetHeight;
    var maxY = parentEloffset + parentElHeight;
    var minY = parentEloffset;

    var maxX = container.offsetWidth;

    var top = randomIntFromInterval(-200, 200)+"px";
    var left = randomIntFromInterval(5*16, container.offsetHeight - 5*16)+"px";

    console.log(maxY);
    console.log(minY);

    eventImages.style.top = top;
    eventImages.style.left = left;


}
/*const eventImages = document.querySelectorAll('.event-image-container');
const container = document.querySelector('.container');
const containerHeight = container.offsetHeight;

eventImages.forEach((image) =>{

    var parentEloffset = image.parentElement.offsetTop;
    var parentElHeight = image.parentElement.offsetHeight;
    var maxY = parentEloffset + parentElHeight;
    var minY = parentEloffset;

    var top = randomIntFromInterval(-200, -100)+"px";
    var left = randomIntFromInterval(0, container.offsetHeight)+"px";

    image.style.top = top;
    image.style.left = left;

});*/

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}