import Swiper, {
    Autoplay,
    EffectFade,
    FreeMode,
    HashNavigation,
    Keyboard,
    Lazy,
    Manipulation,
    Mousewheel,
    Navigation
} from 'swiper';
import 'swiper/css';


export const Carousel = () => {

    const swiperH = new Swiper('.swiper-container', {
        modules: [Navigation, Mousewheel, Autoplay, Keyboard],
        cssMode: false,
        direction: 'horizontal',
        preloadImages: true,
        slidesPerView: 1.75,
        centeredSlides: true,
        navigation:{
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
        },
        spaceBetween: 18,
        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },
        loop: true,
        speed: 300,
    });
}


Carousel();