const scrollToSmoothSettings = {
    // Selectors
    container: ".main",
    targetAttribute: 'data-scroll',
    topOnEmptyHash: true,
    offset: null,
    // Speed and duration
    duration: 300,
    durationRelative: true,
    durationMin: 200,
    durationMax: 400,
    easing: easeOutCubic,
    // Callbacks
    onScrollUpdate: null,
    onScrollEnd: null
};

const smoothScroll = new scrollToSmooth('.anchors a', scrollToSmoothSettings);
smoothScroll.init();


const anchors = document.querySelectorAll('a[data-scroll]');
const sections = document.querySelectorAll('section.is-anchor');


const anchorsMenuContainer = document.querySelector('.anchors');
const progAnchors = document.querySelectorAll('.anchor[data-category]');


console.log(progAnchors);

progAnchors.forEach((anchor) =>{
    anchor.addEventListener('click', function (){
        progAnchors.forEach((anchor) =>{
            anchor.classList.remove('active');
        });
       anchor.classList.add('active');
    });
});

/*anchorsMenuContainer.addEventListener('click', function (){
    anchorsMenuContainer.scroll({
        behavior: 'smooth',
        left: 100,
        top: 0,
    });
})*/

/*anchors.forEach((anchor) => {
    anchor.addEventListener("click", (e) => {
        anchors.forEach((anchor) => {
            anchor.classList.remove('active');
        });
        anchor.classList.add('active');
    });
});*/



var scrollTimeout = null;
anchorsMenuContainer?.addEventListener('scroll', () => {
    if (scrollTimeout) clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(function(){scrollTimeout = null},250);
});

window.addEventListener('scroll', () => {

    var scrollTop = document.querySelector('html').scrollTop;

    sections.forEach((section) => {
        const sectionId = "#" + section.getAttribute('id');
        const anchor = document.querySelector('.anchor[data-scroll="' + sectionId + '"]');

        var elementOffset = section.offsetTop,
            distance = (elementOffset - scrollTop);

        if (distance <= 50) {
            anchorOffset = anchor.offsetLeft;
            anchors.forEach((anchor) => {
                anchor.classList.remove('active');
            });
            anchor.classList.add('active');
            if (anchor.dataset.scroll === "#section-3" || anchor.dataset.scroll === "#section-4") {
                if (scrollTimeout == null) {
                    anchorsMenuContainer.scroll({
                        behavior: 'smooth',
                        left: 100,
                        top: 0,
                    });
                }
            } else {
                if (scrollTimeout == null) {
                    anchorsMenuContainer.scroll({
                        behavior: 'smooth',
                        left: 0,
                        top: 0,
                    });
                }
            }

        } else {
            anchor.classList.remove('active');
        }

    });

});