const body = document.body;
const container = document.querySelector('.container');
const main = document.querySelector('main');
const stickyContainer = document.querySelector('.cover-container');


/*
if(body.dataset.template === "event") {
    let wheelTimeout;

    body.addEventListener('wheel', (event) => {
        if (event.target === body) {
            event.preventDefault();
        }

        if (wheelTimeout) {
            clearTimeout(wheelTimeout);
        }

        wheelTimeout = setTimeout(() => {
            if (event.deltaY < 0) {
                if (main.scrollTop == 0) {
                    stickyContainer.classList.remove('slide-up');
                }
            } else if (event.deltaY > 0) {
                stickyContainer.classList.add('slide-up');
            }
        }, 25);
    })

    window.addEventListener('click', (e) => {
        console.log(body.scrollTop);
    });


    stickyContainer.addEventListener('transitionend', () => {
        if (stickyContainer.classList.contains('slide-up')) {
                main.style.overflow = "scroll";
        } else if (main.scrollTop === 0) {
            main.style.overflow = "hidden";
        }
    });
}*/

