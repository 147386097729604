const themePickerEl = document.querySelectorAll('.theme');
var theme = window.localStorage.getItem('theme');

if(theme !== null){
    document.documentElement.setAttribute("id", theme);
}


themePickerEl.forEach((themeEl) =>{
    themeEl.addEventListener('click', () =>{
        const theme = themeEl.dataset.theme;
        document.documentElement.setAttribute("id", theme);
        window.localStorage.setItem('theme', theme);
    });
});