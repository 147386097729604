__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';



/* Dependencies */

/* Website Modules */
import './_modules/menu';
import './_modules/theme-picker';
import './_modules/events-list';
import './_modules/smoothscroll';
import './_modules/swiper';
import './_modules/filters';
import './_modules/mousewheel';
import ScrollMagic from 'scrollmagic';

document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();
});