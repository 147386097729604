const menuToggler = document.querySelector('.menu-toggler');
const menuCloser =  document.querySelector('.menu-closer');
const menuOverlay = document.querySelector('.menu-overlay');
const body = document.body;

menuToggler.addEventListener('click', () =>{
    menuOverlay.classList.add('show');
    body.classList.add('menu-is-active');
    document.documentElement.classList.add('menu-is-active');

});

menuCloser.addEventListener('click', () =>{
    menuOverlay.classList.remove('show');
    document.documentElement.classList.remove('menu-is-active');
    body.classList.remove('menu-is-active');
});

document.addEventListener("keydown", function (e){
    if(e.keyCode == 27){
        menuOverlay.classList.remove('show');
        document.documentElement.classList.remove('menu-is-active');
        body.classList.remove('menu-is-active');
    }
});
