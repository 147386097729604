const filters = document.querySelectorAll('.filters .filter');
const events = document.querySelectorAll('.event-item');

filters.forEach((filter) => {
    filter.addEventListener('click', () => {
        const category = filter.dataset.category
        console.log(category);
        events.forEach((event) => {
            if(event.dataset.category !== category){
                event.classList.add('hide');
            }else{
                event.classList.remove('hide');
            }
        });
    });
});


